import React from 'react'


export default function index() {
  return (
   
        //return some html
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
   
  )
}
